import React from "react"
const UserTestimonial = props => {
  return (
        <div className="single-user-testimonial text-center mx-3 px-4 py-5 my-5 pb-3">
          {/* quotes */}
          <div className="user-testimonial-quotes">
            <svg
            width={11}
            height={15}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 11 15"
            >
              <path
                fill="#0B2239"
                d="M6.07.03A5.94 5.94 0 0 0 1.8 1.28 4.79 4.79 0 0 0 0 4.98C0 7.3 1.83 9.35 4.4 9.8a11.92 11.92 0 0 1-2.43 3.46.97.97 0 0 0-.11 1.26c.22.31.58.48.97.48.11 0 .22 0 .33-.04 2.3-.61 7.7-2.77 7.84-9.7v-.1A5.3 5.3 0 0 0 6.07.03Z"
              />
            </svg>

            <svg
            width={11}
            height={15}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 11 15"
            >
              <path
                fill="#0B2239"
                d="M6.07.03A5.94 5.94 0 0 0 1.8 1.28 4.79 4.79 0 0 0 0 4.98C0 7.3 1.83 9.35 4.4 9.8a11.92 11.92 0 0 1-2.43 3.46.97.97 0 0 0-.11 1.26c.22.31.58.48.97.48.11 0 .22 0 .33-.04 2.3-.61 7.7-2.77 7.84-9.7v-.1A5.3 5.3 0 0 0 6.07.03Z"
              />
            </svg>
          </div>
          <img src={props.imgSource} alt={props.userName} />
          <small>{props.userComment}</small>
          <small className="d-block pt-3">{props.userName}</small>
        </div>
  )
}

export default UserTestimonial

import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
export default function Home() {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        {/* Main/index page meta tags */}
        <meta property="og:url" content="TODO" />
        <meta
          property="og:title"
          content={t("My Training App - Daily Workout Routine")}
        />
        <meta
          property="og:description"
          content={t(
            "Exercise at home or at the gym with our fitness app! Track your workouts, do all the sets and reps and customize exercises!"
          )}
        />
        <meta property="og:image" content="TODO" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:width" content="50" />
        <meta property="og:image:alt" content="TODO" />

        <meta
          name="description"
          content={t(
            "Exercise at home or at the gym with our fitness app! Track your workouts, do all the sets and reps and customize exercises!"
          )}
        />
        <meta name="keywords" content={t("metaKeywords")} />

        <title>{t("pageTitle")}</title>
      </Helmet>
      <Layout />
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

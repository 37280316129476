const userReviewsData = [
  {
    userNameSr: "Milan Janković",
    userNameEn: "Kevin Rolland",
    userCommentSr:
      "Obožavam mojTrening aplikaciju, radi sve što mi treba i što će mi ikad trebati!",
      userCommentEn: "I love My Training app, it works and does exactly what I need it."
  },
  {
    userNameSr: "Stefan Popović",
    userNameEn: "John Miller",
    userCommentSr: "Predobra aplikacija, koristim je svakog dana!",
    userCommentEn: "The best app, I use it every day!"
  },
  {
    userNameSr: "Jana Perić",
    userNameEn: "Katie Johnson",
    userCommentSr:
      "Ja mislim da je ovo jedina aplikacija koja mi je zadržala focus i pažnju.",
      userCommentEn: "It's the only app that made me keep wanting to work out!"
  },
  {
    userNameSr: "Bojan Krstić",
    userNameEn: "Bob Smith",
    userCommentSr: "Radi savršeno, promenila mi je život svaka čast!",
    userCommentEn: "It's working perfectly, changed my life!"
  },
  {
    userNameSr: "Miroslav Živić",
    userNameEn: "Steve Orton",
    userCommentSr: "Ja vežbam od kad znam za sebe, ovo je otkriće decenije!",
    userCommentEn: "I've been exercising all my life, this app makes everything much easier!"
  },
  {
    userNameSr: "Demir Aydin",
    userNameEn: "Demir Aydin",
    userCommentSr: "Odlicna aplikacija.",
    userCommentEn: "I love this app!"
  },
]

export default userReviewsData

import React from "react"

export default function DumbellsSvg() {
  return (
    <svg
      className="dumbell-icon bg-white position-relative"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 36 36"
    >
      <path
        fill="none"
        d="M32.69 17.23a1.4 1.4 0 0 0 0-1.99L20.76 3.31a1.4 1.4 0 0 0-2 1.99L30.7 17.23a1.4 1.4 0 0 0 1.99 0ZM2.32 31.7l1.99-2 1.98 2-1.98 1.98zm31.86-18.94a1.4 1.4 0 0 0 0-1.99l-8.95-8.95a1.4 1.4 0 0 0-1.99 0l-1 1L33.2 13.74ZM10.77 34.18a1.4 1.4 0 0 0 1.99 0l1-1L2.8 22.26l-1 1a1.4 1.4 0 0 0 0 1.98ZM29.7 4.3l2-1.98 1.98 1.98-1.99 2zM15.24 32.69a1.4 1.4 0 0 0 2-1.99L5.3 18.77a1.4 1.4 0 0 0-1.99 1.98Z"
      />
      <path
        fill="#0b2239"
        d="M3.31 28.71.82 31.2a.7.7 0 0 0 0 1l2.99 2.98a.7.7 0 0 0 1 0l2.48-2.5 2.48 2.5a2.81 2.81 0 0 0 3.98 0l1.06-1.06a2.81 2.81 0 0 0 3.42-4.41l-3.98-3.98 3.43-3.44a.7.7 0 0 0-.99-1l-3.43 3.44-2-1.99 11.48-11.47 2 1.99-3.58 3.57a.7.7 0 0 0 1 .99l3.57-3.57 3.98 3.98a2.81 2.81 0 0 0 4.4-3.42l1.06-1.06a2.81 2.81 0 0 0 0-3.97l-2.48-2.5 2.49-2.48a.7.7 0 0 0 0-1L32.19.83a.7.7 0 0 0-1 0l-2.48 2.5-2.48-2.5a2.81 2.81 0 0 0-3.98 0l-1.06 1.06a2.81 2.81 0 0 0-3.42 4.41l3.98 3.98-11.48 11.48-3.98-3.98a2.81 2.81 0 0 0-4.4 3.42L.81 22.25a2.81 2.81 0 0 0 0 3.97ZM31.7 2.31l2 2-2 1.98-1.98-1.98Zm-8.45-.5a1.4 1.4 0 0 1 1.99 0l8.95 8.96a1.4 1.4 0 0 1 0 1.99l-1 1L22.26 2.8ZM18.77 5.3a1.4 1.4 0 0 1 1.99-1.99l11.93 11.93a1.4 1.4 0 1 1-1.99 2ZM4.3 33.68l-2-1.98 2-2 1.98 2Zm-1-14.91a1.4 1.4 0 0 1 1.99 0L17.23 30.7a1.4 1.4 0 0 1-1.98 1.99L3.3 20.75a1.4 1.4 0 0 1 0-1.98Zm-1.5 4.47 1-1L13.75 33.2l-1 1a1.4 1.4 0 0 1-1.98 0l-8.95-8.96a1.4 1.4 0 0 1 0-1.99Z"
      />
      <path fill="#0b2239" d="M19.4 18.84a.7.7 0 1 0 .71.7.7.7 0 0 0-.7-.7Z" />
    </svg>
  )
}

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundSlider from "gatsby-image-background-slider"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import {
  Trans,
  I18nextContext,
} from "gatsby-plugin-react-i18next"

import GooglePlayDesktop from "./svg/Hero/GooglePlayDesktop"
import GooglePlayMobile from "./svg/Hero/GooglePlayMobile"
import Header from "./Header"

const Hero = () => {
  const LanguageContext = React.useContext(I18nextContext)

  const breakpoints = useBreakpoint()

  const imagesQueryHero = useStaticQuery(graphql`
    query {
      backgrounds: allFile(
        filter: { sourceInstanceName: { eq: "backgrounds" } }
      ) {
        nodes {
          relativePath
          childImageSharp {
            fluid(quality: 75, webpQuality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      heroImageEn: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "mockup-showing-listed-exercises-2x-en.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      heroImageSr: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "mockup-showing-listed-exercises-2x-sr.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <div className="hero wrapper">
      <div className="hero min-vh-100 flex bg-">
        {/* displays background image slider on lg and above */}
        {!(breakpoints.xs || breakpoints.sm || breakpoints.md) && (
          <BackgroundSlider
            query={imagesQueryHero}
            initDelay={3} // delay before the first transition (if left at 0, the first image will be skipped initially)
            transition={2} // transition duration between images
            duration={6} // how long an image is shown
            className="hero-bg-gatsby-wrapper"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "#0B2239",
            }}
            imgClassName="hero-bg-image"
            imgStyle={{
              objectPosition: "right",
            }}
            fadeIn={true}
            durationFadeIn={1500}
          ></BackgroundSlider>
        )}
        <Header />

        <div className="container">
          <div className="pt-5 row position-relative">
            <div className="col-12 col-lg-6">
              <h4 className="text-primary spaced">
                <Trans>BE YOUR BEST</Trans>
              </h4>
              <h1 className="text-white">
                <Trans>Create and track your</Trans>
                <span className="text-primary">
                  {" "}
                  <Trans>workout</Trans>
                </span>
              </h1>
              <p className="py-3 text-white">
                <Trans>
                  MyTraining improves the training experience, allowing you to
                  plan, execute and keep track of your training and progress
                </Trans>
              </p>
              {/* Google Play Button Desktop */}
              <div className="d-none d-lg-block">
                <a href="https://play.google.com/store/apps/details?id=rs.mojtrening" target="_blank" rel="noreferrer">
                  <GooglePlayDesktop />
                </a>
              </div>
            </div>
            <div className="col-8 mx-auto col-lg-4 col-xl-3 d-flex justify-content-center ms-lg-5 ps-0">
              {LanguageContext.language === "en" ? (
                <GatsbyImage image={getImage(imagesQueryHero.heroImageEn)} />
              ) : (
                <GatsbyImage image={getImage(imagesQueryHero.heroImageSr)} />
              )}
            </div>
            {/* Google Play Button on Mobile */}
            <div className="mx-auto col-6 d-flex justify-content-center d-block d-lg-none py-5 pb-lg-0">
              <GooglePlayMobile />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero

import React from "react"
import Hero from "./Hero"
import Main from "./Main"
import Footer from "./Footer"

import "../../scss/All.scss"

export default function Layout() {
  return (
    <div className="layout">
      <Hero />
      <Main />
      <Footer />
    </div>
  )
}

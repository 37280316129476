import React from "react"
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery } from "gatsby"
import UserTestimonial from "./UserTestimonial"
import userReviewsData from "./userReviewsData"
import { Trans, I18nextContext } from "gatsby-plugin-react-i18next"
// svg imports
import DumbellsSvg from "./svg/Main/DumbellsSvg"
import GooglePlayButton from "./svg/Main/GooglePlayButton"
import ManLiftingWeightsSvg from "./svg/Main/ManLiftingWeightsSvg"
import BarbellSvg from "./svg/Main/BarbellSvg"
import GymBikeSvg from "./svg/Main/GymBikeSvg"
import PhoneLogSvg from "./svg/Main/PhoneLogSvg"
import StatisticsSvg from "./svg/Main/StatisticsSvg"
import CalendarSvg from "./svg/Main/CalendarSvg"

export default function Main() {
  const imagesQueryMain = useStaticQuery(graphql`
    query {
      userTestimonialImages: allFile(
        filter: { sourceInstanceName: { eq: "users" } }
      ) {
        nodes {
          relativePath
          childImageSharp {
            fluid(quality: 75, webpQuality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      mockupPhonesWhiteEn: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "mockups-with-white-theme-2x-en.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mockupPhonesWhiteSr: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "mockups-with-white-theme-2x-sr.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mockupPhonesDarkEn: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "mockups-with-dark-theme-2x-en.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mockupPhonesDarkSr: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "mockups-with-dark-theme-2x-sr.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const context = React.useContext(I18nextContext)

  return (
    <div className="main min-vh-100 bg-white">
      <div className="container">
        {/* 1ST CARD */}
        <div className="grid-container py-5">
          <div className="grid-item-1-image mx-auto col-8 col-xxl-12 order-xxl-first">
            <GatsbyImage
              image={getImage(
                context.language === "en"
                  ? imagesQueryMain.mockupPhonesWhiteEn
                  : imagesQueryMain.mockupPhonesWhiteSr
              )}
            />
          </div>
          {/* title */}
          <div className="grid-item-1-title order-first">
            <h2 className="text-secondary mb-4">
              <span className="text-primary">
                <Trans>Personalize</Trans>
              </span>{" "}
              <Trans>workout plan that works best for you</Trans>
            </h2>
          </div>
          {/* description items */}
          <div className="grid-item-1-desc mt-3 mt-xxl-0">
            {/* 1st Item */}
            <div className="d-flex flex-column flex-xxl-row align-items-center align-items-xxl-start">
              {/* svg icon */}
              <ManLiftingWeightsSvg />
              {/* Description */}
              <p className="ps-md-2 pt-2 pt-xxl-0 text-center text-xxl-start">
                <Trans>
                  MojTrening app lets you define exercises, combine them to form
                  workouts, and schedule workouts to make routines.{" "}
                </Trans>
              </p>
            </div>
            {/* 2nd Item */}
            <div className="d-flex flex-column flex-xxl-row align-items-center align-items-xxl-start">
              {/* svg icon */}
              <BarbellSvg />
              {/* Description */}
              <p className="ps-md-2 pt-2 pt-xxl-0 text-center text-xxl-start">
                <Trans>
                  Find 50+ exercises targeting specific body parts, for
                  different levels.{" "}
                </Trans>
              </p>
            </div>
            {/* 3rd Item */}
            <div className="d-flex flex-column flex-xxl-row align-items-center align-items-xxl-start">
              {/* svg icon */}
              <GymBikeSvg />
              {/* Description */}
              <p className="ps-md-2 pt-2 pt-xxl-0 text-center text-xxl-start">
                <Trans>
                  You can create your own exercises as many as you like.{" "}
                </Trans>
              </p>
            </div>
          </div>
        </div>

        {/* 2ND CARD */}
        <div className="grid-container mb-5 py-5">
          <div className="grid-item-2-image mx-auto col-8 col-xxl-12 order-xxl-first">
            <GatsbyImage
              image={getImage(
                context.language === "en"
                  ? imagesQueryMain.mockupPhonesDarkEn
                  : imagesQueryMain.mockupPhonesDarkSr
              )}
            />
          </div>
          {/* title */}
          <div className="grid-item-2-title order-first">
            <h2 className="text-secondary mb-4">
              <Trans>Provide a </Trans>
              <span className="text-primary">
                <Trans>
                  <Trans>clear view</Trans>
                </Trans>
              </span>{" "}
              <Trans>towards completing your goal</Trans>
            </h2>
          </div>
          {/* items */}
          <div className="grid-item-2-desc mt-3 mt-xxl-0">
            {/* 1st Item */}
            <div className="d-flex flex-column flex-xxl-row align-items-center align-items-xxl-start">
              {/* svg icon */}
              <PhoneLogSvg />
              {/* Description */}
              <p className="ps-md-2 pt-2 pt-xxl-0 text-center text-xxl-start">
                <Trans>
                  Having a log of your workouts is a great addition to your
                  fitness routines.{" "}
                </Trans>
              </p>
            </div>
            {/* 2nd Item */}
            <div className="d-flex flex-column flex-xxl-row align-items-center align-items-xxl-start">
              {/* svg icon */}
              <StatisticsSvg />
              {/* Description */}
              <p className="ps-md-2 pt-2 pt-xxl-0 text-center text-xxl-start">
                <Trans>
                  Tracking workouts helps you stay motivated, see improvements,
                  stay organized and on target, and watch your fitness story
                  over time.{" "}
                </Trans>
              </p>
            </div>
            {/* 3rd Item */}
            <div className="d-flex flex-column flex-xxl-row align-items-center align-items-xxl-start">
              {/* svg icon */}
              <CalendarSvg />
              {/* Description */}
              <p className="ps-md-2 pt-2 pt-xxl-0 text-center text-xxl-start">
                <Trans>
                  Schedule workouts in your calendar at the beginning of each
                  week instead of planning to get to a workout “sometime.”
                </Trans>
              </p>
            </div>
          </div>
        </div>
        {/* Horizontal Rule line */}
        <div className="d-flex justify-content-center align-items-center">
          <div className="dumbell-hr-line-a d-flex"></div>
          <div className="dumbell-wrapper d-flex justify-content-center px-3">
            {/* DumbellsSvg svg */}
            <DumbellsSvg></DumbellsSvg>
          </div>
          <div className="dumbell-hr-line-b d-flex"></div>
        </div>

        {/* User Testimonials */}
        <h3 className="text-secondary text-center pt-5">
          <Trans>See what our users say</Trans>
        </h3>
        <div className="d-grid gap-1 pt-5 testimonials-container mb-5">
          {
            /* Mapping through User Testimonial cards based on media query, search for customBreakpoints in gatsby-config.js */
            userReviewsData.map((user, index) => {
              if (imagesQueryMain.userTestimonialImages.nodes[index]) {
                userReviewsData[index].imgSource =
                  imagesQueryMain.userTestimonialImages.nodes[
                    index
                  ].childImageSharp.fluid.src
                if (imagesQueryMain.userTestimonialImages.nodes[index + 1]) {
                  userReviewsData[index + 1].imgSource =
                    imagesQueryMain.userTestimonialImages.nodes[
                      index + 1
                    ].childImageSharp.fluid.src
                }
              }
              return (
                <UserTestimonial
                  userName={context.language === "en"
                  ? userReviewsData[index].userNameEn
                  : userReviewsData[index].userNameSr
                  }

                  userComment={context.language === "en"
                  ? userReviewsData[index].userCommentEn
                  : userReviewsData[index].userCommentSr
                  }
                  imgSource={userReviewsData[index].imgSource}
                ></UserTestimonial>
              )
            })
          }

          {/* user testimonial slider for mobile */}
        </div>
      </div>
      {/* Call to Action - Ready to get started */}
      <div className="call-to-action-wrapper">
        <div className="call-to-action-bg bg-info w-100">
          <StaticImage
            imgClassName="call-to-action-gatsby-image"
            src={"./../assets/images/image-ready-to-get-started-dekstop-2x.png"}
            alt="Mockup showing listed exercises"
            style={{ height: "20rem" }}
          />
          <div className="call-to-action-content">
            <h1 className="text-white text-center">
              <Trans>Ready to get started?</Trans>
            </h1>
            <p className="text-white text-center">
              <Trans>
                Work out and achieve the results you’ve always wanted
              </Trans>
            </p>

            {/* Google Play Button main */}
            <div className="d-flex justify-content-center pt-3 pt-md-0  pt-xl-4">
              <a href="https://play.google.com/store/apps/details?id=rs.mojtrening" target="_blank" rel="noreferrer">
                <GooglePlayButton></GooglePlayButton>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
